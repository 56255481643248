import { FC, SVGProps } from 'react';

interface IArrowUp extends SVGProps<SVGSVGElement> {}

const ArrowUp: FC<IArrowUp> = ({ width = 17, height = 10, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.792202 8.65561L1.51183 9.37524L8.468 2.41907L15.4242 9.37524L16.1438 8.65562L8.468 0.979815L0.792202 8.65561Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ArrowUp;
