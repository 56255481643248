import { FC, SVGProps } from 'react';

interface IArrowDown extends SVGProps<SVGSVGElement> {}

const ArrowDown: FC<IArrowDown> = ({ width = 17, height = 9, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.1336 1.08877L15.414 0.369143L8.45778 7.32531L1.50161 0.369141L0.781982 1.08877L8.45778 8.76457L16.1336 1.08877Z"
        fill="#191919"
      />
    </svg>
  );
};

export default ArrowDown;
